import { Component, OnInit } from '@angular/core';
import {
    makeStateKey,
    Meta,
    Title,
    TransferState,
} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BANNERS_DB } from 'src/app/config/banners.db';
import { plataformaForBrowser } from 'src/app/config/browser';
import { BlogUtilService } from 'src/app/modules/blog/services/blog-util.service';
import { Usuario } from 'src/app/modules/usuarios/models/usuario.model';
import { HeaderService } from 'src/app/services/header.service';
import { MateriaService } from 'src/app/services/materia.service';

@Component({
    selector: 'app-responsavel',
    templateUrl: './responsavel.component.html',
    styleUrls: ['./responsavel.component.scss'],
})
export class ResponsavelComponent implements OnInit {
    responsavel: Usuario | null = null;
    carregando!: boolean;
    banners = BANNERS_DB;

    constructor(
        private route: ActivatedRoute,
        private header: HeaderService,
        private title: Title,
        private meta: Meta,
        private materiaService: MateriaService,
        private router: Router,
        private transferState: TransferState,
        private blogUtil: BlogUtilService
    ) {
        this.header.menor();
    }

    ngOnInit(): void {
        this.route.params.subscribe(async (p) => {
            this.responsavel = null;
            //   const responsavelKey = makeStateKey('responsavel')
            //   if (this.transferState.hasKey(responsavelKey)) {
            //     console.log("Pegando responsavel do HTML.")
            //     this.carregando = true
            //     const usuario = this.transferState.get<Usuario | null>(responsavelKey, null)
            //     if (usuario?.nome) {
            //       this.responsavel = usuario
            //       this.carregando = false
            //     }
            //     this.transferState.remove(responsavelKey)
            //   } else {
            this.carregando = true;
            const usuario = await this.materiaService.buscaUsuario(p.id);
            if (usuario?.nome) {
                this.responsavel = usuario;
                this.carregando = false;
                //   if (!plataformaForBrowser) this.transferState.set<Usuario>(responsavelKey, this.responsavel)
            }
            //   }
            if (this.responsavel) {
                this.title.setTitle(
                    'Convergente - Artigos enviados por ' +
                        this.responsavel.nome
                );
                this.meta.updateTag({
                    name: 'description',
                    content:
                        'Confira os artigos enviados por ' +
                        this.responsavel.nome +
                        '! Seu portal em Santa Teresa - ES traz o melhor conteúdo para você.',
                });
                this.meta.updateTag({
                    property: 'og:url',
                    content:
                        'https://www.convergente.news/equipe/' +
                        this.responsavel.id +
                        '/' +
                        this.blogUtil.criaLinkUnico(this.responsavel.nome),
                });
                this.meta.updateTag({
                    property: 'og:title',
                    content: this.responsavel.nome,
                });
                this.meta.updateTag({
                    property: 'og:description',
                    content:
                        'Confira os artigos enviados por ' +
                        this.responsavel.nome +
                        '! Seu portal em Santa Teresa - ES traz o melhor conteúdo para você.',
                });
                this.meta.updateTag({
                    property: 'og:image',
                    content: <string>this.responsavel.imagem,
                });
                this.meta.updateTag({
                    property: 'og:image:secure_url',
                    content: <string>this.responsavel.imagem,
                });
                this.meta.updateTag({
                    property: 'og:image:type',
                    content: 'image/jpeg',
                });
                this.meta.updateTag({
                    property: 'og:image:width',
                    content: '300',
                });
                this.meta.updateTag({
                    property: 'og:image:height',
                    content: '300',
                });
            } else {
                this.router.navigateByUrl('/nao-encontrado');
            }
        });
    }
}
