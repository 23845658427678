import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BlogPost } from '../../models/blog-post.model'

@Component({
  selector: 'app-blog-lista-posts',
  templateUrl: './blog-lista-posts.component.html',
  styleUrls: ['./blog-lista-posts.component.scss']
})
export class BlogListaPostsComponent implements OnInit {

  @Input() prefixoRota!: string
  @Input() posts!: BlogPost[]
  @Input() botaoMais = true
  @Input() paginacao = false
  @Input() paginas!: number
  @Input() paginaAtual: number = 1
  @Input() paginasExtras: 1 | 2 = 1
  @Input() tamanho: 1 | 2 | 3 = 2
  @Input() tags: boolean = true
  @Input() responsavel: boolean = false
  @Input() carregando!: boolean
  @Input() paginacaoAcima: boolean = false
  @Input() mostraPendente: boolean = false
  @Output() paginar = new EventEmitter<boolean>()
  @Output() maisMaterias = new EventEmitter<boolean>()
  arrayPaginas: number[] = []

  constructor(
  ) { }

  ngOnInit() {
    if (this.paginas) {
      for (let i = 1; i <= this.paginas; i++) {
        this.arrayPaginas.push(i)
      }
    }
  }

}
