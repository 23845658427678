import { Pipe, PipeTransform } from '@angular/core'
import { BlogUtilService } from '../services/blog-util.service'

@Pipe({
  name: 'linkUnico'
})
export class BlogLinkUnicoPipe implements PipeTransform {

  constructor(
    private utils: BlogUtilService
  ) { }

  async transform(value: string | undefined) {
    if (value) return await this.utils.criaLinkUnico(value)
    else return null
  }

}
