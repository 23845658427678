<div class="flex flex-wrap justify-evenly" *ngIf="usuarios">
  <app-miniatura-usuario class="m-3" [tamanho]="tamanho" [usuario]="usuario" *ngFor="let usuario of usuarios">
  </app-miniatura-usuario>
  <p *ngIf="!usuarios?.length && !carregando">Sem usuários.</p>
  <p *ngIf="carregando">Carregando...</p>
</div>
<div class="w-full p-3 flex justify-center" *ngIf="botaoMais">
  <button class="botao" (click)="mais.emit(true)" [disabled]="!aindaTemUsuarios || !usuarios?.length || carregando">
    Mais Usuários
  </button>
</div>