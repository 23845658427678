<section id="listaMaterias">
    <h1>Artigos</h1>
    <h3>Confira todos os artigos publicados no portal</h3>

    <app-publicidade [banner]="banners[0]"></app-publicidade>

    <app-blog-lista-posts (paginar)="paginar()" [carregando]="carregando" [tamanho]="3" [posts]="resposta.dados"
        [paginas]="resposta.paginas" [paginaAtual]="resposta.pagina" [paginacao]="true" [paginacaoAcima]="true"
        prefixoRota="/materia/" *ngIf="resposta">
    </app-blog-lista-posts>

    <app-carregando *ngIf="!resposta"></app-carregando>

    <app-publicidade [banner]="banners[1]"></app-publicidade>

</section>