<article class="w-full max-w-5xl mx-auto">
    <div class="p-3">
        <p *ngIf="post.remocao" class="text-red-500 font-bold text-xl uppercase text-center">Este post foi removido</p>
        <h1 class="text-2xl sm:text-3xl mt-3 text-left">{{post.titulo}}</h1>
        <p class="text-lg text-left my-4">{{post.resumo}}</p>
        <div class="block mt-5 -mb-5 text-sm w-full font-bold" *ngIf="post.responsavel || post.autor">
            <span *ngIf="post.autor">
                {{post.autor}}
            </span>
            <a routerLink="{{rotaAutor}}{{post.responsavelId}}/{{post.responsavel | linkUnico | async}}"
                *ngIf="!post.autor && rotaAutor">
                {{post.responsavel}}
            </a>
            <p *ngIf="!post.autor && !rotaAutor">
                {{post.responsavel}}
            </p>
        </div>
        <div class="flex items-center justify-between mt-5 text-sm w-full">
            <div>
                <div>
                    {{post.publicacao | date:"longDate"}}
                </div>
                <div>
                    atualizada em {{post.alteracao | date:"longDate"}}
                </div>
            </div>
            <button class="flex items-center" (click)="shareClick.emit(post)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 mr-2" viewBox="0 0 512 512">
                    <title>Compartilhar</title>
                    <circle cx="128" cy="256" r="48" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="32" />
                    <circle cx="384" cy="112" r="48" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="32" />
                    <circle cx="384" cy="400" r="48" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="32" />
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="32" d="M169.83 279.53l172.34 96.94M342.17 135.53l-172.34 96.94" />
                </svg>
                <span class="hidden sm:inline-block">Compartilhar</span>
            </button>
        </div>
    </div>
    <picture *ngIf="post.imagem">
        <img [src]="post.imagem" class="imagem" alt="{{post.descricaoImagem}}">
    </picture>
    <div class="px-3 text-sm -mt-3" *ngIf="post.imagem" id="descricao" name="descricao" type="text"
        placeholder="Insira uma descrição para a imagem">{{post.descricaoImagem}}</div>
    <div class="p-3">
        <div id="texto-blog" [ngClass]="{'mt-4' : !post.imagem}" [innerHTML]="post.texto"></div>
        <div *ngIf="post.referencias?.length" class="border rounded py-2 px-3 mt-8">
            <h2 class="mt-2 font-bold text-lg">Referências</h2>
            <ng-container *ngFor="let referencia of post.referencias; index as i">
                <div id="referencia{{i+1}}" *ngIf="referencia.url" class="my-2">
                    <a [href]="referencia.url" class="break-words" target="_blank" rel="noopener noreferer">
                        <sup class="mr-2">{{i+1}}</sup>{{referencia.texto || referencia.url}}
                    </a>
                </div>
                <div id="referencia{{i+1}}" *ngIf="!referencia.url" class="my-2">
                    <span class="break-words"><sup class="mr-2">{{i+1}}</sup>{{referencia.texto}}</span>
                </div>
            </ng-container>
        </div>
        <div class="flex flex-col items-center sm:flex-row mt-8 italic rounded border py-2 px-3"
            *ngIf="post.assinatura && !post.autor">
            <picture class="h-20 w-20 bg-no-repeat m-2 inline-block flex-shrink-0 rounded-full overflow-hidden"
                *ngIf="post.assinaturaImagem">
                <img class="w-full h-full object-cover object-center" [src]="post.assinaturaImagem"
                    alt="imagem de {{post.autor}}">
            </picture>
            <div class="flex flex-col items-center text-center sm:items-start sm:ml-4 sm:text-justify">
                <div class="font-bold text-lg">{{post.responsavel}}</div>
                <div>{{post.assinatura}}</div>
            </div>
        </div>
        <div *ngIf="post.tags?.length" class="flex flex-wrap items-baseline border rounded py-2 px-3 mt-8">
            <p class=" font-bold text-lg m-0 mr-2">Tags:</p>
            <ng-container *ngIf="!rotaTags">
                <p class="mx-1 tag" *ngFor="let tag of post.tags" (click)="tagClick.emit(tag)">
                    {{tag.nome}}
                </p>
            </ng-container>
            <ng-container *ngIf="rotaTags">
                <a routerLink="{{rotaTags}}{{tag.link}}" class="mx-1 tag" *ngFor="let tag of post.tags"
                    (click)="tagClick.emit(tag)">
                    {{tag.nome}}
                </a>
            </ng-container>
        </div>
    </div>
</article>
