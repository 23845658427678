import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { BANNERS_DB } from 'src/app/config/banners.db'
import { ResultadoPosts } from 'src/app/modules/blog/models/blog-api.model'
import { HeaderService } from 'src/app/services/header.service'
import SwiperCore, {
  Autoplay,
  Pagination
} from 'swiper/core'
SwiperCore.use([Pagination, Autoplay])

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit {

  resposta!: ResultadoPosts
  aindaTemMateria!: boolean
  carregando!: boolean
  banners = BANNERS_DB

  constructor(
    title: Title,
    header: HeaderService,
    meta: Meta
  ) {
    header.maior()
    title.setTitle("Convergente")
    meta.updateTag({ name: "description", content: "Seu portal de opinião em Santa Teresa-ES, a primeira colonização italiana no Brasil. Um catálago de notícias com tudo o que você gostaria de saber." })
    meta.updateTag({ property: "og:url", content: "https://www.convergente.news/principal" })
  }

  async ngOnInit() {
  }

}
