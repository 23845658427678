<h2>Fale Conosco</h2>
<h3>Deixe-nos uma mensagem, indicação de matéria ou sugestão.</h3>
<form class="formulario" [formGroup]="formulario" (ngSubmit)="enviar()">
    <div class="campoForm">
        <label for="nome">Nome</label>
        <input type="text" name="nome" id="nome" formControlName="nome">
    </div>
    <div class="inputWrapper">
        <div class="campoForm menor">
            <label for="celular">Celular</label>
            <input type="tel" name="celular" id="celular" formControlName="celular">
        </div>
        <div class="campoForm menor">
            <label for="assunto">Assunto</label>
            <input type="text" name="assunto" id="assunto" formControlName="assunto">
        </div>
    </div>
    <div class="campoForm">
        <label for="mensagem">Mensagem</label>
        <textarea name="mensagem" id="mensagem" cols="30" rows="5" formControlName="mensagem"></textarea>
    </div>
    <a class="botao" (click)="enviar()">Enviar</a>
</form>
