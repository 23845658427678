import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ApoiarComponent } from './componentes/apoiar/apoiar.component'
import { EquipeComponent } from './componentes/equipe/equipe.component'
import { MaisMateriasComponent } from './componentes/mais-materias/mais-materias.component'
import { MateriaComponent } from './componentes/materia/materia.component'
import { NaoEncontradoComponent } from './componentes/nao-encontrado/nao-encontrado.component'
import { PrincipalComponent } from './componentes/principal/principal.component'
import { PrincipiosComponent } from './componentes/principios/principios.component'
import { PrivacidadeComponent } from './componentes/privacidade/privacidade.component'
import { ResponsavelComponent } from './componentes/responsavel/responsavel.component'
import { TagComponent } from './componentes/tag/tag.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'principal'
  },
  {
    path: 'principal',
    component: PrincipalComponent,
  },
  {
    path: 'principios',
    component: PrincipiosComponent,
  },
  {
    path: 'equipe',
    component: EquipeComponent,
  },
  {
    path: 'equipe/:id/:nomeUnico',
    component: ResponsavelComponent,
  },
  {
    path: 'equipe/:nomeUnico',
    component: ResponsavelComponent,
  },
  {
    path: "materia",
    component: MaisMateriasComponent,
  },
  {
    path: "materia/:id/:linkUnico",
    component: MateriaComponent,
  },
  {
    path: "materia/:linkUnico",
    component: MateriaComponent,
  },
  {
    path: "tag/:tag",
    component: TagComponent,
  },
  {
    path: 'apoiar',
    component: ApoiarComponent
  },
  {
    path: 'privacidade',
    component: PrivacidadeComponent
  },
  {
    path: '**',
    component: NaoEncontradoComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
