import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { MiniaturaUsuarioComponent } from './components/miniatura-usuario/miniatura-usuario.component'
import { LinkUnicoPipe } from './pipes/link-unico.pipe'
import { UsuariosComponent } from './usuarios.component'



@NgModule({
  declarations: [
    UsuariosComponent,
    MiniaturaUsuarioComponent,
    LinkUnicoPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  exports: [
    UsuariosComponent,
    MiniaturaUsuarioComponent,
    LinkUnicoPipe
  ]
})
export class UsuariosModule { }
