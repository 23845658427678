<div id="inicio">
    <header [class]="header.class">
        <app-menu class="print-hidden" #menu [links]="menuLinks" [top]="header.top"></app-menu>
        <div class="voltarContainer print-hidden">
            <picture
                class="botaoVoltar clicavel"
                *ngIf="!header.class"
                (click)="voltar(); menu.fecharMenu()"
            ></picture>
        </div>
        <div class="logoWrapper">
            <a
                class="clicavel transition-all logo bg-primaria"
                [ngClass]="{ 'h-20': header.class, 'h-16': !header.class }"
                routerLink="/principal"
            >
            <img class="logo object-contain object-center w-full h-full" src="assets/logo.png" alt="logo do convergente">
        </a>
        </div>
        <hr class="divisorPrincipal" *ngIf="header.class" />
    </header>

    <main (click)="menu.fecharMenu()">
        <section class="router">
            <router-outlet></router-outlet>
        </section>
    </main>

    <a class="botaoFlutuante" *ngIf="scrollTop > 50" (click)="topo()">
        <picture class="setaCima"></picture>
    </a>

    <footer id="rodape" (click)="menu.fecharMenu()">
        <div class="wrapperTermos">
            <div><a routerLink="/privacidade">Política de Privacidade</a></div>
            <div>
                <a href="https://www.hs.tec.br">Desenvolvido por HS.TEC</a>
            </div>
        </div>
    </footer>

    <ng-container *appClientRender>
        <div class="privacidadeWrapper" *ngIf="!cookieService.isConsented">
            <div class="privacidadeAlerta">
                <div class="privacidadeTexto">
                    Para melhorar a sua experiência, nós utilizamos cookies em
                    nosso site. Ao continuar navegando, você aceita a utilização
                    deste tipo de recurso, bem como a nossa
                    <a
                        routerLink="/privacidade"
                        style="color: var(--secundaria)"
                        ><b>Política de Privacidade.</b></a
                    >
                </div>
                <a class="botao" (click)="aceitar($event)">Aceitar</a>
            </div>
        </div>
    </ng-container>
</div>
