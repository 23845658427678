export const MENU_LINKS = [
    { titulo: 'Principal', routerLink: '/principal' },
    { titulo: 'Artigos', routerLink: '/materia' },
    // { titulo: 'Cultura', routerLink: '/secao/cultura' },
    // { titulo: 'Meio Ambiente', routerLink: '/secao/meio-ambiente' },
    // { titulo: 'Economia', routerLink: '/secao/economia' },
    // { titulo: 'Geral', routerLink: '/secao/geral' },
    null,
    { titulo: 'Princípios Editoriais', routerLink: '/principios' },
    { titulo: 'Equipe', routerLink: '/equipe' },
    { titulo: 'Apoie o Portal', routerLink: '/apoiar' },
    { titulo: 'Política de Privacidade', routerLink: '/privacidade' },
    null,
    { titulo: 'Redes Sociais', routerLink: '/principal', fragment: 'redessociais' },
    { titulo: 'Fale Conosco', routerLink: '/principal', fragment: 'faleconosco' }
];
