import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { BANNERS_DB } from 'src/app/config/banners.db'
import { HeaderService } from 'src/app/services/header.service'

@Component({
  selector: 'app-apoiar',
  templateUrl: './apoiar.component.html',
  styleUrls: ['./apoiar.component.scss']
})
export class ApoiarComponent implements OnInit {

  banner = BANNERS_DB[2]

  constructor(header: HeaderService, title: Title, meta: Meta) {
    header.menor()
    title.setTitle("Convergente - Apoie o nosso portal")
    meta.updateTag({ property: "og:title", content: "Apoie o nosso portal" })
    meta.updateTag({ property: "og:url", content: "https://www.convergente.news/apoiar" })
    meta.updateTag({ property: "og:image", content: "https://apiblobs.blob.core.windows.net/convergente/banners/convergente_pequeno.jpg" })
    meta.updateTag({ property: "og:image:secure_url", content: "https://apiblobs.blob.core.windows.net/convergente/banners/convergente_pequeno.jpg" })
    meta.updateTag({ property: "og:image:type", content: "image/jpeg" })
    meta.updateTag({ property: "og:image:width", content: "320" })
    meta.updateTag({ property: "og:image:height", content: "500" })
    meta.updateTag({ property: "og:type", content: "article" })
  }

  ngOnInit(): void {
  }



}
