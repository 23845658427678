import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ResultadoPosts } from 'src/app/modules/blog/models/blog-api.model'
import { Usuario } from 'src/app/modules/usuarios/models/usuario.model'
import { MateriaService } from 'src/app/services/materia.service'

@Component({
  selector: 'app-lista-materias',
  templateUrl: './lista-materias.component.html',
  styleUrls: ['./lista-materias.component.scss']
})
export class ListaMateriasComponent implements OnInit {

  resposta!: ResultadoPosts
  @Input() responsavel!: Usuario
  @Input() carregando!: boolean
  @Input() paginacao = false

  constructor(
    private materiaService: MateriaService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit() {
    this.route.queryParams.subscribe(async p => {
      this.carregando = true
      const pagina = p.pagina ? parseInt(p.pagina) : 1
      if (!this.responsavel) {
        this.resposta = await this.materiaService.listaMaterias(pagina)
      } else {
        if (this.responsavel.resposta?.pagina == pagina) this.resposta = this.responsavel.resposta
        else this.resposta = await this.materiaService.listaMaterias(pagina, this.responsavel.id)
      }
      this.carregando = false
    })
  }

  paginar() {
    console.log("paginou")
    document.getElementById('listaMaterias')?.scrollIntoView({ block: 'start', inline: 'start', behavior: 'auto' })
  }

  maisMaterias() {
    this.materiaService.scrollInicio()
  }

}
