import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    DomSanitizer,
    makeStateKey,
    Meta,
    Title,
    TransferState,
} from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BANNERS_DB } from 'src/app/config/banners.db';
import { plataformaForBrowser } from 'src/app/config/browser';
import { BlogPost } from 'src/app/modules/blog/models/blog-post.model';
import { CookieService } from 'src/app/services/cookie.service';
import { HeaderService } from 'src/app/services/header.service';
import { MateriaService } from 'src/app/services/materia.service';

@Component({
    selector: 'app-materia',
    templateUrl: './materia.component.html',
    styleUrls: ['./materia.component.scss'],
})
export class MateriaComponent implements OnInit, OnDestroy {
    @Input() materia!: BlogPost;
    materias!: BlogPost[];
    carregando = false;
    identifier: null | string = null;
    atualizacao!: number;
    banners = BANNERS_DB;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public materiaService: MateriaService,
        private header: HeaderService,
        private title: Title,
        public cookieService: CookieService,
        private meta: Meta,
        private transferState: TransferState,
        private sanitizer: DomSanitizer
    ) {}

    async ngOnInit() {
        this.route.params.subscribe(async (p) => this.checaParams(p));
        this.materiaService
            .listaMaterias()
            .then((r) => (this.materias = r.dados));
    }

    async checaParams(params: Params) {
        const { id, linkUnico } = params;
        this.carregando = true;
        console.log('Params:', id || linkUnico);
        if (id || linkUnico) {
            if (plataformaForBrowser) await this.materiaService.scrollInicio();
            //   const materiaKey = makeStateKey<BlogPost>('blogPost')
            //   if (this.transferState.hasKey(materiaKey)) {
            //     const materia = this.transferState.get<BlogPost | null>(materiaKey, null)
            //     this.transferState.remove(materiaKey)
            // if (materia && (materia.link == linkUnico || materia.id == id)) {
            //       this.materia = { ...materia }
            //     }
            //   } else {
            const materia = await this.materiaService.buscaMateria(
                id || linkUnico
            );
            this.materia = { ...materia };
            // if (!plataformaForBrowser) this.transferState.set(materiaKey, materia)
            //   }
        } else {
            this.router.navigate(['/nao-encontrado']);
        }
        if (this.materia) {
            if (this.materia.texto)
                this.materia.texto = this.sanitizer.bypassSecurityTrustHtml(
                    this.materia.texto
                ) as string;
            const imagem = this.materia.imagem;
            this.identifier = '/materia/' + this.materia.link;
            this.header.menor();
            this.title.setTitle('Convergente - ' + this.materia.titulo);
            this.meta.updateTag({
                name: 'description',
                content: <string>this.materia.resumo,
            });
            this.meta.updateTag({
                property: 'og:url',
                content: `https://www.convergente.news/materia/${this.materia.id}/${this.materia.link}`,
            });
            this.meta.updateTag({
                property: 'og:title',
                content: <string>this.materia.titulo,
            });
            this.meta.updateTag({
                property: 'og:description',
                content: <string>this.materia.resumo,
            });
            if (this.materia.publicacao)
                this.meta.updateTag({
                    property: 'og:article:published_time',
                    content: `${this.materia.publicacao}`,
                });
            if (this.materia.alteracao)
                this.meta.updateTag({
                    property: 'og:article:modified_time',
                    content: `${this.materia.alteracao}`,
                });
            this.meta.updateTag({
                property: 'og:image',
                content: <string>imagem,
            });
            this.meta.updateTag({ property: 'og:type', content: 'article' });
            this.meta.updateTag({
                name: 'twitter:card',
                content: 'summary_large_image',
            });
        } else {
            this.router.navigate(['/nao-encontrado']);
        }
        this.carregando = false;
    }

    ngOnDestroy() {
        if (plataformaForBrowser) {
            const flickrScript = document.getElementById('flicker-script');
            if (flickrScript) {
                flickrScript.remove();
            }
        }
    }

    async compartilha(materia: BlogPost) {
        if (navigator.share)
            await navigator.share({
                text: materia.resumo,
                title: materia.titulo,
                url: `https://www.convergente.news/materia/${materia.id}/${materia.link}`,
            });
    }
}
