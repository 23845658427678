<article>
    <h1>Como apoiar o Convergente</h1>
    <app-publicidade [banner]="banner"></app-publicidade>
    <p>Sempre precisamos de ajuda. No momento, queremos te oferecer a oportunidade de anunciar em nosso site
        e obter uma ótima visibilidade, com um banner semelhante a este! Ganhe clientes enquanto ajuda nosso portal
        a crescer! Entre em contato
        conosco:</p>
    <p>Email: <a
            href="mailto:publicidade@convergente.news?subject=Quero Anunciar&body=Olá, quero anunciar no Convergente.">publicidade@convergente.news</a>
    </p>
</article>