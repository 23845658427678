import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { plataformaForBrowser } from 'src/app/config/browser';
import { HeaderService } from 'src/app/services/header.service';

@Component({
    selector: 'app-privacidade',
    templateUrl: './privacidade.component.html',
    styleUrls: ['./privacidade.component.scss']
})
export class PrivacidadeComponent implements OnInit {

    constructor(private header: HeaderService, title: Title, meta: Meta) {
        title.setTitle("Convergente - Política de Privacidade");
        meta.updateTag({ name: "description", content: "Aqui no Convergente cuidamos da sua privacidade! Seja em Santa Teresa-ES ou em qualquer outro lugar, levamos sua segurança à sério." });
        meta.updateTag({ property: "og:url", content: "https://www.convergente.news/privacidade" });
        meta.updateTag({ property: "og:title", content: "Política de Privacidade" });
        meta.updateTag({ property: "og:description", content: "Aqui no Convergente cuidamos da sua privacidade! Seja em Santa Teresa-ES ou em qualquer outro lugar, levamos sua segurança à sério." });
        meta.updateTag({ property: "og:type", content: "article" });
        header.menor();
        if (plataformaForBrowser) {
            document.getElementById("inicio")?.scroll({ top: 0 });
        }
    }

    ngOnInit(): void {
    }

}
