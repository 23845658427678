import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { BlogListaPostsComponent } from './components/blog-lista-posts/blog-lista-posts.component'
import { BlogMiniaturaComponent } from './components/blog-miniatura/blog-miniatura.component'
import { BlogPaginacaoComponent } from './components/blog-paginacao/blog-paginacao.component'
import { BlogVisualizadorComponent } from './components/blog-visualizador/blog-visualizador.component'
import { BlogLinkUnicoPipe } from './pipes/blog-link-unico.pipe'


@NgModule({
  declarations: [
    BlogVisualizadorComponent,
    BlogMiniaturaComponent,
    BlogLinkUnicoPipe,
    BlogListaPostsComponent,
    BlogPaginacaoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  providers: [
  ],
  exports: [
    BlogVisualizadorComponent,
    BlogMiniaturaComponent,
    BlogListaPostsComponent,
    BlogLinkUnicoPipe,
    BlogPaginacaoComponent
  ],
})
export class BlogModule { }
