import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ResultadoPosts } from '../modules/blog/models/blog-api.model';
import { BlogPost } from '../modules/blog/models/blog-post.model';
import { Usuario } from '../modules/usuarios/models/usuario.model';
import { DBService } from './db.service';

@Injectable({ providedIn: 'root' })
export class MateriaService {
    usuarios: Usuario[] = [];
    banners: BlogPost[] = [];
    responsavel!: Usuario;
    respostas: ResultadoPosts[] = [];

    constructor(private db: DBService, private transferState: TransferState) {}

    async scrollInicio() {
        const inicio = document.getElementById('inicio');
        inicio?.scrollTo({ top: 0 });
    }

    async listaTags() {
        return this.db.listaTags();
    }

    async listaMaterias(
        pagina = 1,
        responsavelId: number | '' = ''
    ): Promise<ResultadoPosts> {
        //if (this.respostas.length == 0) await this.checaEstado()
        if (!responsavelId) {
            if (this.respostas[pagina]) {
                return this.respostas[pagina];
            } else {
                const materias = await this.db.listaMaterias(
                    pagina,
                    responsavelId
                );
                this.popularResposta(materias);
                this.respostas[pagina] = materias;
                return materias;
            }
        } else {
            console.log('Buscando materias na api...');
            return this.db.listaMaterias(pagina, responsavelId);
        }
    }

    async listaMateriasTag(pagina = 1, tag: string): Promise<ResultadoPosts> {
        return this.db.listaMateriasTag(pagina, tag);
    }

    buscaMateria(idOuLink: number | string): Promise<BlogPost> {
        return new Promise<BlogPost>((resolve, reject) => {
            const timer = Date.now();
            console.log('Buscando materia na api...');
            this.db.buscaMateria(idOuLink).subscribe(
                (materia) => {
                    console.log('Resposta da api em', Date.now() - timer, 'ms');
                    resolve(materia);
                },
                (erro) => {
                    reject(erro);
                }
            );
        });
    }

    async buscaUsuario(id: number): Promise<Usuario> {
        return new Promise<Usuario>((resolve) => {
            const timer = Date.now();
            console.log('Buscando usuário na api...');
            this.db.buscaUsuario(id).subscribe((usuario) => {
                console.log('Resposta da api em', Date.now() - timer, 'ms');
                resolve(usuario);
            });
        });
    }

    async listaUsuarios(): Promise<Usuario[]> {
        return new Promise<Usuario[]>((resolve) => {
            if (this.usuarios.length) {
                resolve(this.usuarios);
            } else {
                const timer = Date.now();
                console.log('Buscando usuários na api...');
                this.db.listaUsuarios().subscribe((usuarios) => {
                    this.usuarios = usuarios.sort(
                        (a, b) => Math.random() - Math.random()
                    );
                    resolve(this.usuarios);
                });
                console.log('Resposta da api em', Date.now() - timer, 'ms');
            }
        });
    }

    private popularResposta(resposta: ResultadoPosts) {
        this.respostas[resposta.pagina] = resposta;
        if (resposta.dados?.length && !this.banners?.length) {
            console.log('Populando banners');
            this.banners.push(...resposta.dados.slice(0, 5));
        }
    }

    // private async checaEstado() {
    //     const respostaKey = makeStateKey<ResultadoPosts>('respostaInicial');
    //     const timer = Date.now();
    //     if (this.transferState.hasKey(respostaKey)) {
    //         const respostaState = this.transferState.get<ResultadoPosts | null>(
    //             respostaKey,
    //             null
    //         );
    //         if (respostaState) {
    //             this.popularResposta(respostaState);
    //             console.log('Resposta veio no HTML:', respostaState);
    //         } else {
    //             const resposta = await this.db.listaMaterias();
    //             this.popularResposta(resposta);
    //             console.log('Resposta da api em', Date.now() - timer, 'ms');
    //         }
    //         this.transferState.remove(respostaKey);
    //     } else {
    //         const resposta = await this.db.listaMaterias();
    //         this.popularResposta(resposta);
    //         this.transferState.set(respostaKey, resposta);
    //         console.log('Resposta da api em', Date.now() - timer, 'ms');
    //     }
    // }
}
