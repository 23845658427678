<app-blog-visualizador *ngIf="materia && !carregando" [post]="materia" rotaAutor="/equipe/" rotaPost="/materia/"
    rotaTags="/tag/" (shareClick)="compartilha($event)" (tagClick)="materiaService.scrollInicio()"></app-blog-visualizador>

<app-carregando *ngIf="carregando"></app-carregando>

<app-publicidade [banner]="banners[0]"></app-publicidade>

<div class="comentarios" *appClientRender>
    <disqus *ngIf="cookieService.isConsented && identifier" [identifier]="identifier"></disqus>
    <p *ngIf="!cookieService.isConsented">Aceite nossa política de privacidade para ver os comentários!</p>
</div>

<div *appClientRender>
    <h2>Leia mais</h2>
    <app-lista-materias></app-lista-materias>
</div>

<app-publicidade [banner]="banners[1]"></app-publicidade>
