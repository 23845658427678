import { Pipe, PipeTransform } from '@angular/core'
import { UsuarioUtilService } from '../services/usuario-utils.service'

@Pipe({
  name: 'linkUnico'
})
export class LinkUnicoPipe implements PipeTransform {

  constructor(
    private utils: UsuarioUtilService
  ) { }

  async transform(value: string) {
    return await this.utils.criaLinkUnico(value)
  }

}
