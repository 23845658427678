<ng-container *ngIf="tamanho == 1">
    <a routerLink="{{prefixoRota}}{{post.id}}/{{post.titulo | linkUnico | async}}"
        class="transition-all shadow-md hover:shadow-lg overflow-hidden mx-auto h-20 flex w-80 max-w-full">
        <div *ngIf="post.imagem" class="bg-no-repeat bg-center bg-cover h-20 w-20 flex-shrink-0"
            style="background-image: url('{{post.imagem}}');">
        </div>
        <div class="flex flex-col justify-between h-full p-3">
            <div id="miniatura-conteudo" class="overflow-hidden h-full flex-grow-0">
                <h3 class="text-left primaria mx-0 ellipsis font-bold mt-0 mb-1">{{post.titulo}}</h3>
                <div class="ellipsis text-gray-700">{{post.resumo}}</div>
                <div *ngIf="!post.publicacao && mostraPendente"
                    class="bg-red-500 py-1 my-2 text-white text-center font-bold">Pendente de revisão</div>
            </div>
        </div>
    </a>
</ng-container>

<ng-container *ngIf="tamanho == 2">
    <a routerLink="{{prefixoRota}}{{post.id}}/{{post.titulo | linkUnico | async}}"
        class="block transition-all shadow-md hover:shadow-lg overflow-hidden mx-auto h-full w-80 max-w-full">
        <div *ngIf="post.imagem" class="bg-no-repeat bg-center bg-cover h-40"
            style="background-image: url('{{post.imagem}}');">
        </div>
        <div class="flex flex-col justify-between p-3">
            <div id="miniatura-conteudo" class="overflow-hidden flex-grow-0">
                <div class="ellipsis flex-shrink-0 text-gray-600 text-sm" *ngIf="responsavel">
                    {{post.responsavel}}
                </div>
                <h3 class="text-left primaria mx-0 font-bold mt-0 mb-2">{{post.titulo}}</h3>
                <div class="text-gray-700" *ngIf=" post && post.resumo && post.resumo.length> 70">{{post.resumo |
                    slice:0:70}}...
                </div>
                <div class="text-gray-700" *ngIf="post && post.resumo && post.resumo.length <= 70">{{post.resumo}}</div>
            </div>
            <div class="text-gray-500 ellipsis h-8 flex-shrink-0 mt-4" *ngIf="tags && post.tags?.length">
                <span class="mr-2">Tags:</span>
                <span class="mx-1" *ngFor="let tag of post.tags">{{tag.nome}}</span>
            </div>
            <div *ngIf="!post.publicacao && mostraPendente"
                class="bg-red-500 py-1 my-2 text-white text-center font-bold">Pendente de revisão</div>
        </div>
    </a>
</ng-container>

<ng-container *ngIf="tamanho == 3">
    <a routerLink="{{prefixoRota}}{{post.id}}/{{post.titulo | linkUnico | async}}"
        class="block transition-all shadow-md hover:shadow-lg overflow-hidden mx-auto h-full max-w-full"
        style="width: 460px;">
        <div *ngIf="post.imagem" class="bg-no-repeat bg-center bg-cover h-64"
            style="background-image: url('{{post.imagem}}');">
        </div>
        <div class="flex flex-col justify-between p-3">
            <div id="miniatura-conteudo" class="overflow-hidden flex-grow-0">
                <h3 class="text-left primaria mx-0 font-bold mt-0 mb-2">{{post.titulo}}</h3>
                <div class="text-gray-700">{{post.resumo}}</div>
            </div>
            <div class="text-gray-500 media ellipsis h-8 flex-shrink-0 mt-4" *ngIf="tags && post.tags?.length">
                <span class="mr-2">Tags:</span>
                <span class="mx-1" *ngFor="let tag of post.tags">{{tag.nome}}</span>
            </div>
            <div *ngIf="!post.publicacao && mostraPendente"
                class="bg-red-500 py-1 my-2 text-white text-center font-bold">Pendente de revisão</div>
        </div>
    </a>
</ng-container>