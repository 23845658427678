import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Link } from './models/link.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() escondido: boolean = true;
  @Input() mobile: boolean = false;
  @Input() links: (Link | null)[] = [];
  @Input() zindex: number = 1000;
  @Input() top: string = "100px";
  @Input() modal: boolean = false;
  @Input() width: string = "1000px";
  @ViewChild('navMenu') navMenu!: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  public toggleMenu(): void {
    const nav = this.navMenu.nativeElement;
    if (this.escondido && nav) {
      nav.style.top = this.top.valueOf();
      this.escondido = false;
    } else {
      nav?.style.top == "calc(-100vh - 50px)";
      this.escondido = true;
    }
  }

  public fecharMenu() {
    const nav = this.navMenu.nativeElement;
    if (nav) {
      nav?.style.top == "calc(-100vh - 50px)";
      this.escondido = true;
    }
  }

}
