import { Component, OnInit } from '@angular/core';
import {
    makeStateKey,
    Meta,
    Title,
    TransferState,
} from '@angular/platform-browser';
import { plataformaForBrowser } from 'src/app/config/browser';
import { HeaderService } from 'src/app/services/header.service';
import { MateriaService } from 'src/app/services/materia.service';
import { Usuario } from 'src/app/modules/usuarios/models/usuario.model';

@Component({
    selector: 'app-equipe',
    templateUrl: './equipe.component.html',
    styleUrls: ['./equipe.component.scss'],
})
export class EquipeComponent implements OnInit {
    usuarios: Usuario[] = [];
    carregando!: boolean;

    constructor(
        header: HeaderService,
        title: Title,
        meta: Meta,
        public materiaService: MateriaService,
        private transferState: TransferState
    ) {
        title.setTitle('Convergente - Conheça nossa equipe');
        meta.updateTag({
            name: 'description',
            content:
                'Confira nosso Expediente: os colaboradores que contribuem para que você tenha a melhor informação possível em Santa Teresa-ES!',
        });
        meta.updateTag({
            property: 'og:url',
            content: 'https://www.convergente.news/equipe',
        });
        meta.updateTag({
            property: 'og:title',
            content: 'Conheça nossa equipe',
        });
        meta.updateTag({
            property: 'og:description',
            content:
                'Confira nosso Expediente: os colaboradores que contribuem para que você tenha a melhor informação possível em Santa Teresa-ES!',
        });
        header.menor();
        this.randomizarStaff();
    }

    async ngOnInit() {
        // const usuariosKey = makeStateKey('usuarios')
        // if (this.transferState.hasKey(usuariosKey)) {
        //   console.log("Pegando usuarios do HTML.")
        //   this.usuarios = this.transferState.get<Usuario[]>(usuariosKey, [])
        //   this.materiaService.usuarios = this.usuarios
        //   this.transferState.remove(usuariosKey)
        // } else {
        this.carregando = true;
        const usuarios = await this.materiaService.listaUsuarios();
        //   if (!plataformaForBrowser) this.transferState.set<Usuario[]>(usuariosKey, usuarios)
        this.usuarios.push(...usuarios);
        this.carregando = false;
        // }
    }

    randomizarStaff() {
        for (let i = this.usuarios.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * i);
            const temp = this.usuarios[i];
            this.usuarios[i] = this.usuarios[j];
            this.usuarios[j] = temp;
        }
    }
}
