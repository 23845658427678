import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-carregando',
  templateUrl: './carregando.component.html',
  styleUrls: ['./carregando.component.scss']
})
export class CarregandoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
