import { ApplicationRef, Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { SwUpdate } from '@angular/service-worker'
import { concat, interval } from 'rxjs'
import { first } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { Link } from './componentes/menu/models/link.model'
import { plataformaForBrowser } from './config/browser'
import { MENU_LINKS } from './config/menu-links'
import { CookieService } from './services/cookie.service'
import { HeaderService } from './services/header.service'
import { MateriaService } from './services/materia.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  scrollTop = 0;
  title = 'Convergente';
  menuLinks: (Link | null)[] = MENU_LINKS;
  carregado = false;

  constructor(
    private route: ActivatedRoute,
    public header: HeaderService,
    public cookieService: CookieService,
    public materiaService: MateriaService,
    private update: SwUpdate,
    private appRef: ApplicationRef
  ) { }

  ngOnInit() {
    if (plataformaForBrowser) {
      if (environment.production) {
        this.update.available.subscribe(async up => {
          await this.update.activateUpdate()
          document.location.reload()
        })
        const appEstavel$ = this.appRef.isStable.pipe(first(estavel => estavel === true))
        const cadaSeisHoras$ = interval(30 * 60 * 1000)
        const cadaSeisHorasQuandoEstavel$ = concat(appEstavel$, cadaSeisHoras$)
        cadaSeisHorasQuandoEstavel$.subscribe(() => this.update.checkForUpdate())
      }
      this.route.fragment.subscribe(f => {
        if (f) this.scroll(f)
      })
      this.listener()
      if (environment.production) {
        const script = document.createElement('script')
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-LYEWCCSPJQ"
        const script2 = document.createElement('script')
        script2.innerText = `window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); }gtag('js', new Date());gtag('config', 'G-LYEWCCSPJQ');`
        document.querySelector('head')?.appendChild(script)
        document.querySelector('head')?.appendChild(script2)
      }
    }
  }

  topo() {
    const inicio = document.getElementById("inicio")
    inicio?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  scroll(f: string) {
    const elemento = document.getElementById(f)
    if (elemento)
      elemento.scrollIntoView({ inline: 'start', block: 'start' })
    else
      setTimeout(() => {
        this.scroll(f)
      }, 200)
  }

  listener() {
    const inicio = document.getElementById("inicio")
    if (inicio) {
      this.scrollTop = inicio.scrollTop
      inicio.addEventListener("scroll", (e) => {
        this.scrollTop = inicio.scrollTop
      })
    } else {
      setTimeout(() => {
        this.listener()
      }, 200)
    }
  }

  voltar() {
    history.back()
  }

  aceitar(ev: Event) {
    const wrapper = document.querySelector(".privacidadeWrapper") as HTMLDivElement
    wrapper.style.bottom = "-100vh"
    setTimeout(() => {
      this.cookieService.consent(true, ev)
    }, 500)

  }
}
