import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { BANNERS_DB } from 'src/app/config/banners.db'
import { plataformaForBrowser } from 'src/app/config/browser'
import { BlogPost } from 'src/app/modules/blog/models/blog-post.model'
import { HeaderService } from 'src/app/services/header.service'
import { MateriaService } from 'src/app/services/materia.service'

@Component({
  selector: 'app-nao-encontrado',
  templateUrl: './nao-encontrado.component.html',
  styleUrls: ['./nao-encontrado.component.scss'],
})
export class NaoEncontradoComponent implements OnInit {

  innerWidth: number = 0;
  banners = BANNERS_DB
  posts!: BlogPost[]

  constructor(
    private header: HeaderService,
    public materiaService: MateriaService,
    title: Title,
    meta: Meta,
    private router: Router,
  ) {
    title.setTitle("Convergente - Página não encontrada")
    meta.updateTag({ name: "description", content: "Página não encontrada. Mas não desanime! Temos muito conteúdo por aqui, confira o seu portal de opinião em Santa Teresa-ES." })
    meta.updateTag({ property: "og:url", content: "https://www.convergente.news/nao-encontrado" })
    meta.updateTag({ property: "og:title", content: "Página não encontrada" })
    meta.updateTag({ property: "og:description", content: "Página não encontrada. Mas não desanime! Temos muito conteúdo por aqui, confira o seu portal de opinião em Santa Teresa-ES." })

    if (plataformaForBrowser) {
      this.innerWidth = window.innerWidth
      window.addEventListener('resize', () => {
        this.innerWidth = window.innerWidth
      })
    }
  }

  ngOnInit(): void {
    this.router.navigateByUrl('/nao-encontrado')
    this.header.menor()
    this.materiaService.listaMaterias().then(r => this.posts = r.dados)
  }

}
