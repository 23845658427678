import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { BANNERS_DB } from 'src/app/config/banners.db'
import { ResultadoPosts } from 'src/app/modules/blog/models/blog-api.model'
import { HeaderService } from 'src/app/services/header.service'
import { MateriaService } from 'src/app/services/materia.service'

@Component({
  selector: 'app-mais-materias',
  templateUrl: './mais-materias.component.html',
  styleUrls: ['./mais-materias.component.scss']
})
export class MaisMateriasComponent implements OnInit {

  resposta!: ResultadoPosts
  carregando!: boolean
  banners = BANNERS_DB

  constructor(
    private materiaService: MateriaService,
    private route: ActivatedRoute,
    private header: HeaderService,
    private title: Title,
    private meta: Meta
  ) {
    this.header.menor()
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(async p => {
      const pagina = p.pagina ? parseInt(p.pagina) : 1
      this.carregando = true
      this.resposta = await this.materiaService.listaMaterias(pagina)
      this.carregando = false
      this.title.setTitle('Artigos - Convergente')
      this.meta.updateTag({ name: 'description', content: 'Página ' + pagina + '. Confira todos os artigos publicados no seu portal de opinião em Santa Teresa - ES.' })
      this.meta.updateTag({ property: 'og:description', content: 'Página ' + pagina + '. Confira todos os artigos publicados no seu portal de opinião em Santa Teresa - ES.' })
      this.meta.updateTag({ property: 'og:title', content: 'Artigos - Convergente' })
    })
  }

  paginar() {
    document.getElementById('listaMaterias')?.scrollIntoView()
  }

}
