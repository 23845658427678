<section *ngIf="responsavel">
    <div class="containerTitulo">
        <h1 class="text-center">
            Confira os artigos enviados por <span class="primaria font-bold">{{responsavel?.nome}}</span>
        </h1>
    </div>
    <app-publicidade [banner]="banners[0]"></app-publicidade>
    <app-lista-materias [responsavel]="responsavel" [carregando]="carregando" [paginacao]="true">
    </app-lista-materias>
    <app-publicidade [banner]="banners[1]"></app-publicidade>
</section>

<section *ngIf="!responsavel">
    <app-carregando></app-carregando>
</section>