<article class="p-4">
    <h1>Nossa forma de comunicar</h1>
    <img src="https://apiblobs.blob.core.windows.net/convergente/banners/principios_editoriais.jpg"
        class="imagemMateria">
    <div class="textoMateria">
        <p>A comunicação humana é complexa em toda a sua extensão. Mas, as primeiras impressões do sentido são
            orientadas pelo conteúdo, desde as primeiras frases de um texto, começando pela “manchete”, até o desfecho.
            Aqui, pretendemos que sua experiência sensorial não tenha limites.</p>
        <p>Este veículo não deixará nunca o seu propósito básico: informar e sustentar elementos para análises e
            debates. Acreditamos que servir como suporte para o diálogo é atalho para uma comunicação direta e
            reflexiva.</p>
        <p>Para tanto, ofereceremos em nossas edições um leque de assuntos de importância local, não esquecendo que
            somos parte de um todo. Assim, nossos articulistas estarão antenados no que acontece no Espírito Santo, no
            Brasil e no mundo.</p>
        <p>Reforçando nossa premissa queremos, através da boa interlocução, promover o diálogo e servir de bússola para
            os temas mais caros a sociedade de Santa Teresa. </p>
        <p>Promessa é dívida!</p>
    </div>
</article>