<ng-container>
  <section id="principal">
    <app-banners-materias></app-banners-materias>
    <app-publicidade [banner]="banners[0]"></app-publicidade>
    <app-lista-materias></app-lista-materias>
  </section>

  <app-publicidade [banner]="banners[1]"></app-publicidade>

  <hr class="divisorSecao">

  <section id="redessociais">
    <div class="containerSecao">
      <h2>Siga nossas Redes Sociais</h2>
      <h3>Acompanhe todas as novidades diretamente das nossas redes sociais.</h3>
      <div class="iconesRedes">
        <div class="iconesWrapper">
          <a style="background-image: url('/assets/icones/facebook.png')"
            href="https://www.facebook.com/portalconvergente" target="_blank"></a>
          <a style="background-image: url('/assets/icones/youtube.png')"
            href="https://www.youtube.com/channel/UCtlUcqrUhAsWkb_T4cpVqKw" target="_blank"></a>
        </div>
        <div class="iconesWrapper">
          <a style="background-image: url('/assets/icones/instagram.png')"
            href="https://www.instagram.com/convergente.news/" target="_blank"></a>
          <a style="background-image: url('/assets/icones/twitter.png')" href="https://twitter.com/NewsConvergente"
            target="_blank"></a>
        </div>
      </div>
    </div>
  </section>

  <hr class="divisorSecao">

  <section id="faleconosco">
    <div class="containerSecao">
      <app-fale-conosco></app-fale-conosco>
    </div>
  </section>
</ng-container>