import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Usuario } from './models/usuario.model'

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss'],
})
export class UsuariosComponent implements OnInit {

  @Input() tamanho = 1
  @Input() botaoMais = true
  @Input() usuarios!: Usuario[]
  @Input() aindaTemUsuarios = false
  @Input() carregando!: boolean
  @Output() mais = new EventEmitter<boolean>()

  constructor() { }

  ngOnInit() { }

}
