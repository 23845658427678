import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CookieService } from 'src/app/services/cookie.service';
import { NotificaService } from 'src/app/services/notifica.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-fale-conosco',
    templateUrl: './fale-conosco.component.html',
    styleUrls: ['./fale-conosco.component.scss'],
})
export class FaleConoscoComponent implements OnInit {
    formulario = this.formBuilder.group({
        nome: '',
        celular: '',
        assunto: '',
        mensagem: '',
    });

    constructor(
        private formBuilder: FormBuilder,
        private notifica: NotificaService,
        private http: HttpClient,
        private cookieService: CookieService
    ) {}

    ngOnInit(): void {}

    enviar() {
        if (
            this.formulario.value.nome &&
            this.formulario.value.celular &&
            this.formulario.value.assunto &&
            this.formulario.value.mensagem
        ) {
            if (this.cookieService.isConsented) {
                const apiUrl = `${environment.API}/sugestoes?tabela=${environment.TABELA}`;
                this.notifica.toast('Enviando sua mensagem...');
                this.http
                    .post(apiUrl, this.formulario.value, {
                        responseType: 'text',
                    })
                    .subscribe(
                        (res) => {
                            this.notifica.toast('Mensagem enviada.');
                            this.formulario.reset();
                        },
                        (erro) => {
                            this.notifica.toast('Falha no envio da mensagem.');
                        }
                    );
            } else {
                const mensagem =
                    'Você ainda não aceitou a nossa <b>Política de Privacidade</b>.';
                this.notifica.toast(mensagem);
            }
        } else {
            let mensagem = 'Faltou preencher: ';
            const form = this.formulario.value;
            if (!form.nome) mensagem += 'NOME ';
            if (!form.celular) mensagem += 'CELULAR ';
            if (!form.assunto) mensagem += 'ASSUNTO ';
            if (!form.mensagem) mensagem += 'MENSAGEM';
            this.notifica.toast(mensagem);
        }
    }
}
