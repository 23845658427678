import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-blog-paginacao',
  templateUrl: './blog-paginacao.component.html',
  styleUrls: ['./blog-paginacao.component.scss'],
})
export class BlogPaginacaoComponent implements OnInit {

  @Input() paginas!: number
  @Input() paginaAtual!: number
  @Input() paginasExtras: 1 | 2 = 1
  @Output() paginar = new EventEmitter<boolean>()
  arrayPaginas: number[] = []

  constructor() { }

  ngOnInit() {
    if (this.paginas) this.arrayPaginas = Array.from({ length: this.paginas }).map((v, i) => i + 1)
  }

}
