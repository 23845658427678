import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { HeaderService } from 'src/app/services/header.service';

@Component({
  selector: 'app-principios',
  templateUrl: './principios.component.html',
  styleUrls: ['./principios.component.scss']
})
export class PrincipiosComponent implements OnInit {

  constructor(private header: HeaderService, title: Title, meta: Meta) {
    title.setTitle("Convergente - Princípios Editoriais");
    meta.updateTag({ name: "description", content: "Nós buscamos entregar o melhor conteúdo com a melhor qualidade em Santa Teresa-ES. Aqui no Convergente a honestidade é nosso fundamento!" });
    meta.updateTag({ property: "og:url", content: "https://www.convergente.news/principios" });
    meta.updateTag({ property: "og:title", content: "Princípios Editoriais" });
    meta.updateTag({ property: "og:description", content: "Nós buscamos entregar o melhor conteúdo com a melhor qualidade em Santa Teresa-ES. Aqui no Convergente a honestidade é nosso fundamento!" });
    meta.updateTag({ property: "og:image", content: "https://apiblobs.blob.core.windows.net/convergente/banners/principios_editoriais.jpg" });
    meta.updateTag({ property: "og:image:secure_url", content: "https://apiblobs.blob.core.windows.net/convergente/banners/principios_editoriais.jpg" });
    meta.updateTag({ property: "og:image:type", content: "image/jpeg" });
    meta.updateTag({ property: "og:image:width", content: "1000" });
    meta.updateTag({ property: "og:image:height", content: "500" });
    meta.updateTag({ property: "og:type", content: "article" });
    header.menor();
  }

  ngOnInit(): void {
  }

}
