import { AfterViewInit, Component, OnInit } from '@angular/core'
import { BlogPost } from 'src/app/modules/blog/models/blog-post.model'
import { MateriaService } from 'src/app/services/materia.service'
import Swiper from 'swiper'
import Core, { Autoplay, Pagination } from 'swiper/core'
Core.use([Autoplay, Pagination])

@Component({
  selector: 'app-banners-materias',
  templateUrl: './banners-materias.component.html',
  styleUrls: ['./banners-materias.component.scss']
})
export class BannersMateriasComponent implements OnInit, AfterViewInit {

  materiasBanner: BlogPost[] = this.materiaService.banners
  swiper!: Swiper

  constructor(public materiaService: MateriaService) { }

  ngOnInit() {
    console.log("Banners atuais:", this.materiasBanner?.length)
  }

  ngAfterViewInit() {
    this.swiper = new Swiper(".swiper-banners", {
      observer: true,
      slidesPerView: 1,
      autoplay: { disableOnInteraction: false, delay: 5000 },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      loop: true
    })
  }

}
