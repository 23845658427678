<app-blog-paginacao *ngIf="paginacao && paginacaoAcima && paginas > 1" [paginaAtual]="paginaAtual" [paginas]="paginas"
  (paginar)="paginar.emit($event)" [paginasExtras]="paginasExtras">
</app-blog-paginacao>

<div class="flex flex-wrap justify-evenly" *ngIf="posts && !carregando">
  <app-blog-miniatura class="p-3 max-w-full" [tamanho]="tamanho" [tags]="tags" [responsavel]="responsavel" [post]="post"
    [prefixoRota]="prefixoRota" [mostraPendente]="mostraPendente" *ngFor="let post of posts">
  </app-blog-miniatura>
  <p class="p-3" *ngIf="!posts?.length">Sem artigos.</p>
</div>

<div *ngIf="carregando" class="p-3 text-center">
  Carregando...
</div>

<div class="w-full p-3 flex justify-center"
  *ngIf="botaoMais && !paginacao && !carregando && posts?.length && paginas > 1">
  <a class="botao" routerLink="{{prefixoRota}}" [queryParams]="{pagina: 2}" (click)="maisMaterias.emit(true)">
    Mais Artigos
  </a>
</div>

<app-blog-paginacao *ngIf="paginacao && paginas > 1" [paginaAtual]="paginaAtual" [paginas]="paginas"
  (paginar)="paginar.emit($event)" [paginasExtras]="paginasExtras">
</app-blog-paginacao>