import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class NotificaService {
    constructor() { }

    toast(mensagem: string, tempo = 5000, backgroundColor = "var(--secundaria)") {
        const wrapperGeral = document.createElement('div');
        wrapperGeral.style.display = "flex";
        wrapperGeral.style.justifyContent = "center";
        wrapperGeral.style.position = "fixed";
        wrapperGeral.style.top = "-100vh";
        wrapperGeral.style.width = "100%";
        wrapperGeral.style.zIndex = "99999";
        const divWrapper = document.createElement('div');
        divWrapper.style.display = "flex";
        divWrapper.style.alignItems = "center";
        //divWrapper.style.width = "calc(100% - 40px)";
        divWrapper.style.maxWidth = "600px";
        divWrapper.style.boxShadow = "black 2px 2px 5px -4px";
        divWrapper.style.borderRadius = "10px";
        divWrapper.style.backgroundColor = backgroundColor;
        const div = document.createElement('div');
        div.style.width = "calc(100% - 40px)";
        div.style.padding = "10px 20px";
        div.style.fontWeight = "500";
        div.style.color = "white";
        div.style.textAlign = "justify";
        const span = document.createElement('span');
        span.style.width = "40px";
        span.style.height = "40px";
        span.style.display = "flex";
        span.style.alignItems = "center";
        span.style.justifyContent = 'center';
        span.style.padding = "0 0 0 0";
        span.style.cursor = "pointer";
        span.style.backgroundImage = "url('/assets/icones/close.svg')";
        span.style.backgroundRepeat = 'no-repeat';
        span.style.backgroundPosition = 'center';
        span.style.filter = 'invert(1)';
        span.onclick = () => {
            wrapperGeral.style.top = "-100vh";
        };
        div.innerHTML = mensagem;
        divWrapper.appendChild(div);
        divWrapper.appendChild(span);
        wrapperGeral.appendChild(divWrapper);
        document.body.appendChild(wrapperGeral);
        const topInicial = (-wrapperGeral.clientHeight - 50) + "px";
        wrapperGeral.style.top = topInicial;
        wrapperGeral.style.transition = "top 0.3s";
        setTimeout(() => {
            wrapperGeral.style.top = "50px";
        }, 50);
        setTimeout(() => {
            wrapperGeral.style.top = topInicial;
            setTimeout(() => {
                document.body.removeChild(wrapperGeral);
            }, 1000);
        }, tempo);
    }

    aguarde(mensagem = "Aguarde...") {
        const divWrapper = document.createElement('div');
        divWrapper.style.display = "flex";
        divWrapper.style.alignItems = "center";
        divWrapper.style.justifyContent = "center";
        divWrapper.style.position = "fixed";
        divWrapper.style.padding = "20px"
        divWrapper.style.top = "0";
        divWrapper.style.left = "0";
        divWrapper.style.width = "100%";
        divWrapper.style.height = "100%";
        divWrapper.style.zIndex = "99999";
        divWrapper.style.backgroundColor = "#000a";
        const div = document.createElement('div');
        div.style.minWidth = "300px";
        div.style.maxWidth = "100%";
        div.style.padding = "10px 20px";
        div.style.borderRadius = "10px";
        div.style.backgroundColor = "var(--secundaria)";
        div.style.color = "white"
        div.style.fontWeight = "500";
        div.style.textAlign = "center";
        div.style.boxShadow = "black 2px 2px 5px -4px";
        div.innerHTML = mensagem;
        let aguarde = {
            append: () => {
                divWrapper.appendChild(div);
                document.body.appendChild(divWrapper);
            },
            dismiss: () => {
                document.body.removeChild(divWrapper);
            }
        };
        return aguarde;
    }

}