import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { BlogPost, Tag } from '../../models/blog-post.model'

@Component({
  selector: 'app-blog-visualizador',
  templateUrl: './blog-visualizador.component.html',
  styleUrls: ['./blog-visualizador.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlogVisualizadorComponent implements OnInit {

  @Input() post!: BlogPost
  @Input() rotaPost!: string
  @Input() rotaAutor!: string
  @Input() rotaTags!: string
  @Output() tagClick = new EventEmitter<Tag>()
  @Output() shareClick = new EventEmitter<BlogPost>()

  constructor() { }

  ngOnInit(): void {
  }

}
