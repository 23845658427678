<div class="swiper-banners relative">
  <div class="swiper-wrapper">
    <a routerLink="/materia/{{materiaBanner.id}}/{{materiaBanner.link}}" class="swiper-slide banner-imagem text-white"
      style="background-image: url('{{materiaBanner.imagem}}');" *ngFor="let materiaBanner of materiasBanner;">
      <div class="mancheteContainer">
        <div class="manchete" [innerHTML]="materiaBanner.titulo"></div>
        <div class="subtitulo" [innerHTML]="materiaBanner.resumo"></div>
        <div class="overflow-ellipsis whitespace-nowrap overflow-hidden text-gray-300"
          *ngIf="materiaBanner.tags?.length">
          Tags: <span *ngFor="let tag of materiaBanner.tags" class="secaoManchete">{{tag.nome}}</span>
        </div>
      </div>
    </a>
    <app-carregando *ngIf="!this.materiasBanner?.length"></app-carregando>
  </div>
  <div class="swiper-pagination"></div>
</div>