import { registerLocaleData } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import localePt from '@angular/common/locales/pt'
import { LOCALE_ID, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser'
import { ServiceWorkerModule } from '@angular/service-worker'
import { DisqusModule } from "ngx-disqus"
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ApoiarComponent } from './componentes/apoiar/apoiar.component'
import { BannersMateriasComponent } from './componentes/banners-materias/banners-materias.component'
import { CarregandoComponent } from './componentes/carregando/carregando.component'
import { EquipeComponent } from './componentes/equipe/equipe.component'
import { FaleConoscoComponent } from './componentes/fale-conosco/fale-conosco.component'
import { ListaMateriasComponent } from './componentes/lista-materias/lista-materias.component'
import { MaisMateriasComponent } from './componentes/mais-materias/mais-materias.component'
import { MateriaComponent } from './componentes/materia/materia.component'
import { MenuComponent } from './componentes/menu/menu.component'
import { NaoEncontradoComponent } from './componentes/nao-encontrado/nao-encontrado.component'
import { PrincipalComponent } from './componentes/principal/principal.component'
import { PrincipiosComponent } from './componentes/principios/principios.component'
import { PrivacidadeComponent } from './componentes/privacidade/privacidade.component'
import { PublicidadeComponent } from './componentes/publicidade/publicidade.component'
import { ResponsavelComponent } from './componentes/responsavel/responsavel.component'
import { ClientRenderDirective } from './directives/client-render.directive'
import { ServerRenderDirective } from './directives/server-render.directive'
import { BlogModule } from './modules/blog/blog.module'
import { UsuariosModule } from './modules/usuarios/usuarios.module';
import { TagComponent } from './componentes/tag/tag.component'

registerLocaleData(localePt)

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    PrincipalComponent,
    FaleConoscoComponent,
    MateriaComponent,
    PrincipiosComponent,
    EquipeComponent,
    ApoiarComponent,
    NaoEncontradoComponent,
    PrivacidadeComponent,
    PublicidadeComponent,
    BannersMateriasComponent,
    ResponsavelComponent,
    CarregandoComponent,
    ServerRenderDirective,
    ClientRenderDirective,
    ListaMateriasComponent,
    MaisMateriasComponent,
    TagComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserTransferStateModule,
    ReactiveFormsModule,
    FormsModule,
    DisqusModule.forRoot('convergente-1'),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BlogModule,
    UsuariosModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
