import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { plataformaForBrowser } from '../config/browser';

@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    class = '';
    top = '60px';
    //   statusKey = makeStateKey<{ class: string, top: string }>("headerStatus")

    constructor(private transferState: TransferState) {
        // if (this.transferState.hasKey(this.statusKey)) {
        //   const status = this.transferState.get<{ class: string, top: string } | null>(this.statusKey, null);
        //   this.transferState.remove(this.statusKey);
        //   if (status) {
        //     this.class = status.class;
        //     this.top = status.top;
        //   }
        // }
    }

    menor() {
        if (this.class != '' && this.top != '60px') {
            this.class = '';
            this.top = '60px';
            //   this.setaStatus()
        }
    }

    maior() {
        if (this.class != 'headerMaior' && this.top != '100px') {
            this.class = 'headerMaior';
            this.top = '100px';
            //   this.setaStatus()
        }
    }

    //   setaStatus() {
    //     if (plataformaForBrowser) {
    //       const status = { class: this.class, top: this.top }
    //       this.transferState.set(this.statusKey, status);
    //     }
    //   }
}
