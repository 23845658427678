import { Banner } from "../models/banner.model"

export const BANNERS_DB: Banner[] = [
    {
        empresa: "HS.TEC",
        imagem: "https://apiblobs.blob.core.windows.net/convergente/banners/hs-tec.jpg",
        imagemMenor: "https://apiblobs.blob.core.windows.net/convergente/banners/hs-tec_pequeno.jpg",
        link: "https://www.hs.tec.br",
        target: "_blank",
    },
    {
        empresa: "Camila Baracho",
        imagem: "https://apiblobs.blob.core.windows.net/convergente/banners/camila.jpg",
        imagemMenor: "https://apiblobs.blob.core.windows.net/convergente/banners/camila_pequeno.jpg",
        link: "https://instagram.com/camilabaracho/",
        target: "_blank",
    },
    {
        empresa: "Convergente",
        imagem: "https://apiblobs.blob.core.windows.net/convergente/banners/convergente.jpg",
        imagemMenor: "https://apiblobs.blob.core.windows.net/convergente/banners/convergente_pequeno.jpg",
        link: "/apoiar",
        target: "_self"
    },
    {
        empresa: "Pestalozzi",
        imagem: "https://apiblobs.blob.core.windows.net/convergente/banners/pestalozzi.jpg",
        imagemMenor: "https://apiblobs.blob.core.windows.net/convergente/banners/pestalozzi_pequeno.jpg",
        link: "https://wa.me/5527996432230?text=Oi%2C%20vi%20a%20publica%C3%A7%C3%A3o%20do%20p%C3%A9-de-moleque%20no%20site%20do%20convergente%20e%20queria%20fazer%20uma%20encomenda!",
        target: "_blank"
    },
]