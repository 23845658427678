import { Component, Input, OnInit } from '@angular/core'
import { Usuario } from '../../models/usuario.model'

@Component({
  selector: 'app-miniatura-usuario',
  templateUrl: './miniatura-usuario.component.html',
  styleUrls: ['./miniatura-usuario.component.scss'],
})
export class MiniaturaUsuarioComponent implements OnInit {

  @Input() usuario!: Usuario
  @Input() tamanho: number = 2

  constructor() { }

  ngOnInit() { }

}
