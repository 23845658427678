import { Component, Input, OnInit } from '@angular/core'
import { plataformaForBrowser } from 'src/app/config/browser'
import { Banner } from 'src/app/models/banner.model'

@Component({
  selector: 'app-publicidade',
  templateUrl: './publicidade.component.html',
  styleUrls: ['./publicidade.component.scss']
})
export class PublicidadeComponent implements OnInit {

  @Input() banner!: Banner
  pequena = false

  constructor() { }

  ngOnInit(): void {
    if (plataformaForBrowser) {
      if (window.innerWidth > 500)
        this.pequena = false
      else
        this.pequena = true
      window.addEventListener('resize', () => {
        if (window.innerWidth > 500)
          this.pequena = false
        else
          this.pequena = true
      })
    }
  }

}
