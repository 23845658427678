<div class="w-full p-3" *ngIf="paginas">
  <div class="flex flex-wrap justify-center items-center">
    <a class="botao" [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{pagina: paginaAtual - 1}"
      (click)="paginar.emit(true)" *ngIf="paginaAtual > 1">
      &lt;
    </a>
    <span class="botao opacity-30" style="cursor: default;" *ngIf="paginaAtual == 1">
      &lt;
    </span>
    <a class="p-2" [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{pagina: 1}"
      (click)="paginar.emit(true)" [ngClass]="{'font-bold': 1 == paginaAtual, 'text-gray-400': 1 != paginaAtual}">1</a>
    <span class="text-gray-400" *ngIf="paginaAtual > 2 + paginasExtras">...</span>
    <span *ngFor="let pagina of arrayPaginas">
      <a class="p-2" [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{pagina: pagina}"
        (click)="paginar.emit(true)"
        [ngClass]="{'font-bold': pagina == paginaAtual, 'text-gray-400': pagina != paginaAtual}" *ngIf="(pagina == paginaAtual || (pagina <= paginaAtual + paginasExtras && pagina >= paginaAtual - paginasExtras))
        && pagina != 1 && pagina != paginas">
        {{pagina}}
      </a>
    </span>
    <span class="text-gray-400" *ngIf="paginaAtual < paginas - paginasExtras - 1">...</span>
    <a class="p-2" [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{pagina: paginas}"
      (click)="paginar.emit(true)"
      [ngClass]="{'font-bold': paginas == paginaAtual, 'text-gray-400': paginas != paginaAtual}">{{paginas}}</a>
    <a class="botao" [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{pagina: paginaAtual + 1}"
      (click)="paginar.emit(true)" *ngIf="paginaAtual < paginas">
      &gt;
    </a>
    <span class="botao opacity-30" style="cursor: default;" *ngIf="paginaAtual == paginas">
      &gt;
    </span>
  </div>
</div>