<div class="wrapper" [style]="'height: '+top+';'">
    <div class="containerIcone" [style]="'max-width: '+width+'; height: '+top+';'">
        <picture [class]="'icone'+(!escondido ? ' menuAberto' : '')" (click)="toggleMenu()"
            [style]="'z-index: '+ (zindex+100)"></picture>
    </div>
</div>
<nav id="navMenu" #navMenu
    [style]="'top: ' + (escondido ? 'calc(-100vh - 50px)' : top) + ';z-index: '+ (zindex-100) + '; min-height: ' + 'calc(100vh - ' + top + ')'">
    <div *ngFor="let link of links; last as i" class="menuLink" [style]="i ? 'padding-bottom:50px' : ''">
        <a *ngIf="link" [routerLink]="link.routerLink" [fragment]="link.fragment"
            (click)="toggleMenu()">{{link.titulo}}</a>
        <hr *ngIf="!link">
    </div>
</nav>