import { Component, OnInit } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { BANNERS_DB } from 'src/app/config/banners.db'
import { ResultadoPosts } from 'src/app/modules/blog/models/blog-api.model'
import { HeaderService } from 'src/app/services/header.service'
import { MateriaService } from 'src/app/services/materia.service'

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {


  resposta!: ResultadoPosts
  carregando!: boolean
  banners = BANNERS_DB
  tag!: string

  constructor(
    private materiaService: MateriaService,
    private route: ActivatedRoute,
    private header: HeaderService,
    private title: Title,
    private meta: Meta
  ) {
    this.header.menor()
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(async p => {
      const pagina = p.pagina ? parseInt(p.pagina) : 1
      const tag = this.route.snapshot.paramMap.get('tag')
      if (tag) {
        this.tag = tag
        this.carregando = true
        this.resposta = await this.materiaService.listaMateriasTag(pagina, tag)
        this.carregando = false
        this.title.setTitle('Artigos sobre ' + tag + ' - Convergente')
        this.meta.updateTag({ name: 'description', content: 'Página ' + pagina + `. Confira todos os artigos publicados sobre '${tag}' no seu portal de opinião em Santa Teresa - ES.` })
        this.meta.updateTag({ property: 'og:description', content: 'Página ' + pagina + `. Confira todos os artigos publicados sobre '${tag}' no seu portal de opinião em Santa Teresa - ES.` })
        this.meta.updateTag({ property: 'og:title', content: 'Artigos - Convergente' })
      }
    })
  }

  paginar() {
    document.getElementById('listaMaterias')?.scrollIntoView()
  }

}
