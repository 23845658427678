import { Component, Input, OnInit } from '@angular/core'
import { BlogPost } from '../../models/blog-post.model'

@Component({
  selector: 'app-blog-miniatura',
  templateUrl: './blog-miniatura.component.html',
  styleUrls: ['./blog-miniatura.component.scss'],
})
export class BlogMiniaturaComponent implements OnInit {

  @Input() prefixoRota!: string
  @Input() tamanho: 1 | 2 | 3 = 2
  @Input() post!: BlogPost
  @Input() tags = true
  @Input() responsavel = false
  @Input() mostraPendente = false

  constructor() { }

  ngOnInit(): void {
  }

}
