<a routerLink="/equipe/{{usuario.id}}/{{usuario.nome | linkUnico | async}}" id="miniatura-wrapper"
  class="block p-4 transition-all cursor-pointer overflow-hidden" [ngClass]="{
    'h-24 flex': tamanho == 1,
    'max-w-xs': tamanho != 3,
    'max-w-lg': tamanho == 3}">
  <div *ngIf="usuario.imagem" class="bg-no-repeat bg-center bg-cover rounded-full mx-auto mb-3" [ngClass]="{
        'h-20 w-20 m-2 flex-shrink-0': tamanho == 1,
        'h-32 w-32 m-auto': tamanho == 2,
        'h-64 w-64 m-auto': tamanho == 3}" style="background-image: url('{{usuario.imagem}}');">
  </div>
  <div id="miniatura-conteudo" class="overflow-hidden w-full flex-grow-0">
    <ng-container *ngIf="tamanho == 1">
      <p class="mt-0 text-center ellipsis secundaria text-lg font-bold">{{usuario.nome}}</p>
      <p class="mt-2 text-center ellipsis text-gray-700">{{usuario.assinatura}}</p>
    </ng-container>
    <ng-container *ngIf="tamanho == 2">
      <p class="mt-0 text-center secundaria text-xl font-bold">{{usuario.nome}}</p>
      <p class="mt-0 text-center text-gray-700">
        {{usuario.assinatura}}
      </p>
    </ng-container>
    <ng-container *ngIf="tamanho == 3">
      <p class="mt-0 text-center secundaria text-2xl font-bold">{{usuario.nome}}</p>
      <p class="mt-0 text-center text-lg text-gray-700" *ngIf="tamanho == 3">{{usuario.assinatura}}</p>
    </ng-container>
  </div>
</a>