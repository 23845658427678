import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResultadoPosts } from '../modules/blog/models/blog-api.model';
import { BlogPost, Tag } from '../modules/blog/models/blog-post.model';
import { Usuario } from '../modules/usuarios/models/usuario.model';

@Injectable({ providedIn: 'root' })
export class DBService {
    constructor(private http: HttpClient) {}

    buscaMateria(idOuLink: number | string) {
        if (idOuLink) {
            return this.http.get<BlogPost>(
                `${environment.API}/posts/` +
                    idOuLink +
                    `?tabela=${environment.TABELA}`
            );
        } else {
            return new Observable<BlogPost>((sub) => {
                throw 'Não veio idOuLink.';
            });
        }
    }

    listaMaterias(pagina = 1, responsavelId: number | '' = '') {
        pagina = parseInt(pagina.toString());
        return this.http
            .get<ResultadoPosts>(
                `${environment.API}/posts?pagina=${pagina}&responsavelId=${responsavelId}&tabela=${environment.TABELA}`
            )
            .toPromise();
    }

    listaMateriasTag(pagina = 1, tag: string) {
        pagina = parseInt(pagina.toString());
        return this.http
            .get<ResultadoPosts>(
                `${environment.API}/tag?pagina=${pagina}&tag=${tag}&tabela=${environment.TABELA}`
            )
            .toPromise();
    }

    listaTags() {
        return this.http
            .get<Tag[]>(
                `${environment.API}/tags/lista?tabela=${environment.TABELA}`
            )
            .toPromise();
    }

    buscaUsuario(id: number) {
        return this.http.get<Usuario>(
            environment.API + '/usuarios/' + id + `?admin=${environment.ADMIN}`
        );
    }

    listaUsuarios() {
        return this.http.get<Usuario[]>(
            environment.API + '/usuarios' + `?admin=${environment.ADMIN}`
        );
    }
}
